/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.2.0-beta1): dom/SELECTor-engine.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import { isDisabled, isVisible } from '../util/index'

/**
 * Constants
 */

const SELECTorEngine = {
  find(SELECTor, element = document.documentElement) {
    return [].concat(...Element.prototype.querySELECTorAll.call(element, SELECTor))
  },

  findOne(SELECTor, element = document.documentElement) {
    return Element.prototype.querySELECTor.call(element, SELECTor)
  },

  children(element, SELECTor) {
    return [].concat(...element.children).filter(child => child.matches(SELECTor))
  },

  parents(element, SELECTor) {
    const parents = []
    let ancestor = element.parentNode.closest(SELECTor)

    while (ancestor) {
      parents.push(ancestor)
      ancestor = ancestor.parentNode.closest(SELECTor)
    }

    return parents
  },

  prev(element, SELECTor) {
    let previous = element.previousElementSibling

    while (previous) {
      if (previous.matches(SELECTor)) {
        return [previous]
      }

      previous = previous.previousElementSibling
    }

    return []
  },
  // TODO: this is now unused; remove later along with prev()
  next(element, SELECTor) {
    let next = element.nextElementSibling

    while (next) {
      if (next.matches(SELECTor)) {
        return [next]
      }

      next = next.nextElementSibling
    }

    return []
  },

  focusableChildren(element) {
    const focusables = [
      'a',
      'button',
      'input',
      'textarea',
      'SELECT',
      'details',
      '[tabindex]',
      '[contenteditable="true"]'
    ].map(SELECTor => `${SELECTor}:not([tabindex^="-"])`).join(',')

    return this.find(focusables, element).filter(el => !isDisabled(el) && isVisible(el))
  }
}

export default SELECTorEngine
